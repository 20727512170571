@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400&family=Ubuntu:wght@300&display=swap);
.dev-icons {
  padding-left: 0;
  list-style: none;
  font-size: 3rem;
  margin-bottom: 0;
  margin-top: 0;
  text-align: center;
}

.software-skill-inline {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 20px;
}

.software-skill-inline > i {
  color: #fff;
}

.software-skill-inline > i:hover {
  color: #645beb;
}
.software-skill-inline > p {
  color: #fff;
  font-size: 10px;
}
.software-skill-inline > i:hover ~ p{
  color: #645beb;
}

.meter {
    height: 20px;
    position: relative;
    background: rgb(243, 239, 239);
    border-radius: 25px;
}
.meter > span {
    display: block;
    height: 100%;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    background-color: #aaa5ff;
    position: relative;
    overflow: hidden;
}
.skill{
    line-height: 3.5vw;
}
.skill-bars{
    font-size: 28px;
    width: 40%;
}
.skills-container {
    display: flex;
    width: 90%;
    padding: 20px 10px;
    margin: 0px auto;
    margin-top: 2rem;
}
.skills-container > * {
    flex: 1 1;
    margin-bottom: 30px;
}
.skills-image > img {
    margin-left: 80px;
    max-width: 90%;
    height: auto;
}

/* Media Query */

@media (max-width: 1456px) {
    .skills-bar {
        line-height: 4rem;
    }
    .skills-image {
        display: none;
        order: 2;
    }
}

@media (max-width: 768px) {
    .skills-container {
        flex-direction: column
    }
    .skills-bar {
        line-height: 3rem;
        margin: 20px;
    }
    .skills-image {
        display: none;
        order: 2;
    }
}
/* Skillks  */
.skills-main-div {
    display: flex;
}
.skills-text-div {
    margin-left: 50px;
}

.skills-main-div > * {
    flex: 1 1;
    margin-bottom: 30px;
}

.skills-image-div > img {
    max-width: 100%;
    height: auto;
}

.skills-heading {
    font-size: 56px;
    font-weight: 400;
}

/* Media Query */
@media (max-width: 1380px) {
    .skills-heading {
        font-size: 40px;
    }
}
@media (max-width: 768px) {
    .skills-heading {
        font-size: 30px;
        text-align: center;
    }
    .greeting-text-p {
        font-size: 16px;
        line-height: normal;
    }
    .skills-main-div {
        flex-direction: column
    }
    .skills-text-div {
        margin-left: 0px;
        margin: 20px;
    }
    .skills-text {
        font-size: 16px;
    }
    .skills-text-subtitle {
        font-size: 16px;
        text-align: center;
    }
    .skills-image-div {
        /* display: none; */
        order: 2;
    }
}

html {
  font-size: 10px;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(#185a9d, #093660);
  background-attachment: fixed;
}

button {
  background: transparent;
  border: 2px solid white;
  color: white;
  padding: 1rem 2rem;
  border-radius: .5rem;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #00000000;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(255, 255, 255);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(255, 255, 255);
}

h1 {
  color: white;
  font-size: 12rem;
  font-weight: 400;
  margin: 0;
  font-family: 'Josefin Sans', sans-serif;
  max-width: calc(100vw - 4rem);
}

h2 {
  font-family: 'Ubuntu', sans-serif;
  color: white;
  font-size: 2.8rem;
  font-weight: 300;
}

h3 {
  color: white;
  font-family: 'Ubuntu', sans-serif;
  font-size: 2rem;
}

h4 {
  color: white;
  font-family: 'Ubuntu', sans-serif;
  font-size: 1.6rem;
}

button {
  font-family: 'Ubuntu', sans-serif;
  font-size: 2.5rem;
  font-weight: 300;
  cursor: pointer;
}

.MuiTooltip-tooltip {
  font-size: 1.2rem !important;
}

.link {
  color: white;
  text-decoration: none;
}

p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 3rem;
  margin: 0 0 1.5rem;
  font-family: 'Ubuntu', sans-serif;
  max-width: calc(100vw - 4rem);
}

.MuiFormLabel-root {
  font-size: 16px !important;
}

.MuiSelect-select.MuiSelect-select {
  font-size: 1.4rem;
}
@keyframes hide {
  from {
    opacity: 0;
  }
  to {
    opacity: 0;
  }
}

@keyframes enter-left {
  from {
    transform: translateX(-100px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes enter-forwards {
  from {
    transform: translateZ(-100px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes enter-up {
  from {
    transform: translateY(100px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes enter-left-z30 {
  from {
    transform: translateZ(30px) translateX(-100px);
    opacity: 0;
  }
  to {
    transform: translateZ(30px) translateX(0);
    opacity: 1;
  }
}

@keyframes enter-left-zn30 {
  from {
    transform: translateZ(-30px) translateX(-100px);
    opacity: 0;
  }
  to {
    transform: translateZ(-30px) translateX(0);
    opacity: 1;
  }
}

@keyframes enter-right-zn30 {
  from {
    transform: translateZ(-30px) translateX(100px);
    opacity: 0;
  }
  to {
    transform: translateZ(-30px) translateX(0);
    opacity: 1;
  }
}

@keyframes rotatez {
  from {
    transform: translateZ(0px) rotate(0deg);
  }
  to {
    transform: translateZ(0px) rotate(-360deg);
  }
}

@keyframes rotatez-full-z30 {
  from {
    transform: translateZ(30px) rotate(0deg);
  }
  to {
    transform: translateZ(30px) rotate(360deg);
  }
}

@keyframes rotatez-zn30 {
  from {
    transform: translateZ(-30px) rotate(0deg);
  }
  to {
    transform: translateZ(-30px) rotate(360deg);
  }
}

@keyframes enter-right {
  from {
    transform: translateX(100px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-up-z0 {
  from {
    transform: translateZ(0px) translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateZ(0px) translateY(0px);
    opacity: 1;
  }
}

@keyframes slide-up-zn10 {
  from {
    transform: translateZ(-10px) translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateZ(-10px) translateY(0px);
    opacity: 1;
  }
}

@keyframes slide-down-z30 {
  from {
    transform: translateZ(30px) translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateZ(30px) translateY(0px);
    opacity: 1;
  }
}

@keyframes slide-down-z20 {
  from {
    transform: translateZ(20px) translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateZ(20px) translateY(0px);
    opacity: 1;
  }
}

@keyframes slide-up-zn40 {
  from {
    transform: translateZ(-40px) translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateZ(-40px) translateY(0px);
    opacity: 1;
  }
}

@keyframes slide-down-z10 {
  from {
    transform: translateZ(10px) translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateZ(10px) translateY(0px);
    opacity: 1;
  }
}

@keyframes slide-left-z50 {
  from {
    transform: translateZ(50px) translateX(50px);
    opacity: 0;
  }
  to {
    transform: translateZ(50px) translateX(0px);
    opacity: 1;
  }
}

@keyframes slide-right-z50 {
  from {
    transform: translateZ(50px) translateX(-50px);
    opacity: 0;
  }
  to {
    transform: translateZ(50px) translateX(0px);
    opacity: 1;
  }
}

@keyframes hide-50-z40-rotate1deg {
  0% {
    transform: translateZ(40px) rotate(0deg);
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  51% {
    opacity: 0;
  }
  100% {
    transform: translateZ(40px) rotate(-1deg);
    opacity: 0;
  }
}

@keyframes hide-50 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  51% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes hide-50-z20-rotate1deg {
  0% {
    transform: translateZ(20px) rotate(0deg);
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  51% {
    opacity: 0;
  }
  100% {
    transform: translateZ(20px) rotate(-1deg);
    opacity: 0;
  }
}

@keyframes slide-forwards-zn30 {
  from {
    transform: translateZ(-200px);
    opacity: 0;
  }
  to {
    transform: translateZ(-30px);
    opacity: 1;
  }
}

@keyframes slide-forwards-zn10 {
  from {
    transform: translateZ(-200px);
    opacity: 0;
  }
  to {
    transform: translateZ(-10px);
    opacity: 1;
  }
}

@keyframes slide-forwards-z10 {
  from {
    transform: translateZ(-200px);
    opacity: 0;
  }
  to {
    transform: translateZ(10px);
    opacity: 1;
  }
}

@keyframes slide-forwards-z20 {
  from {
    transform: translateZ(-200px);
    opacity: 0;
  }
  to {
    transform: translateZ(20px);
    opacity: 1;
  }
}

@keyframes slide-forwards-zn10 {
  from {
    transform: translateZ(-200px);
    opacity: 0;
  }
  to {
    transform: translateZ(-10px);
    opacity: 1;
  }
}

@keyframes slide-forwards-zn40 {
  from {
    transform: translateZ(-200px);
    opacity: 0;
  }
  to {
    transform: translateZ(-40px);
    opacity: 1;
  }
}

@keyframes slide-forwards-zn5 {
  from {
    transform: translateZ(-200px);
    opacity: 0;
  }
  to {
    transform: translateZ(-5px);
    opacity: 1;
  }
}

@keyframes slide-forwards-zn20 {
  from {
    transform: translateZ(-200px);
    opacity: 0;
  }
  to {
    transform: translateZ(-20px);
    opacity: 1;
  }
}

@keyframes slide-forwards-zn50 {
  from {
    transform: translateZ(-200px);
    opacity: 0;
  }
  to {
    transform: translateZ(-50px);
    opacity: 1;
  }
}

@keyframes slide-forwards-z30 {
  from {
    transform: translateZ(-200px);
    opacity: 0;
  }
  to {
    transform: translateZ(30px);
    opacity: 1;
  }
}

@keyframes slide-backwards-z50 {
  from {
    transform: translateZ(200px);
    opacity: 0;
  }
  to {
    transform: translateZ(50px);
    opacity: 1;
  }
}

@keyframes slide-backwards-zn30 {
  from {
    transform: translateZ(100px);
    opacity: 0;
  }
  to {
    transform: translateZ(-30px);
    opacity: 1;
  }
}

@keyframes slide-backwards-z30 {
  from {
    transform: translateZ(100px);
    opacity: 0;
  }
  to {
    transform: translateZ(30px);
    opacity: 1;
  }
}

@keyframes hoverx-left-z50 {
  0% {
    transform: translateZ(50px) translateX(0px);
  }
  50% {
    transform: translateZ(50px) translateX(-10px);
  }
  100% {
    transform: translateZ(50px) translateX(0px);
  }
}

@keyframes hoverx-right-z50 {
  0% {
    transform: translateZ(50px) translateX(0px);
  }
  50% {
    transform: translateZ(50px) translateX(10px);
  }
  100% {
    transform: translateZ(50px) translateX(0px);
  }
}

@keyframes hoverz-forwards-zn30 {
  0% {
    transform: translateZ(-30px);
  }
  50% {
    transform: translateZ(-45px);
  }
  100% {
    transform: translateZ(-30px);
  }
}

@keyframes hoverz-backwards-zn30 {
  0% {
    transform: translateZ(-30px);
  }
  50% {
    transform: translateZ(-15px);
  }
  100% {
    transform: translateZ(-30px);
  }
}

@keyframes hoverz-backwards-zn20 {
  0% {
    transform: translateZ(-20px);
  }
  50% {
    transform: translateZ(-10px);
  }
  100% {
    transform: translateZ(-20px);
  }
}

@keyframes hoverz-backwards-z30 {
  0% {
    transform: translateZ(30px);
  }
  50% {
    transform: translateZ(45px);
  }
  100% {
    transform: translateZ(30px);
  }
}

@keyframes hoverz-forwards-z30 {
  0% {
    transform: translateZ(30px);
  }
  50% {
    transform: translateZ(15px);
  }
  100% {
    transform: translateZ(30px);
  }
}

@keyframes hoverz-forwardslight-z30 {
  0% {
    transform: translateZ(30px);
  }
  50% {
    transform: translateZ(35px);
  }
  100% {
    transform: translateZ(30px);
  }
}

@keyframes hoverz-backwards-z50 {
  0% {
    transform: translateZ(50px);
  }
  50% {
    transform: translateZ(-5px);
  }
  100% {
    transform: translateZ(50px);
  }
}

@keyframes hoverz-forwards-z50 {
  0% {
    transform: translateZ(50px);
  }
  50% {
    transform: translateZ(60px);
  }
  100% {
    transform: translateZ(50px);
  }
}

@keyframes hoverz-forwards-zn40 {
  0% {
    transform: translateZ(-40px);
  }
  50% {
    transform: translateZ(-30px);
  }
  100% {
    transform: translateZ(-40px);
  }
}


@keyframes hoverz-forwards-zn10 {
  0% {
    transform: translateZ(-10px);
  }
  50% {
    transform: translateZ(-20px);
  }
  100% {
    transform: translateZ(-10px);
  }
}

@keyframes hoverz-forwards-z20 {
  0% {
    transform: translateZ(20px);
  }
  50% {
    transform: translateZ(30px);
  }
  100% {
    transform: translateZ(20px);
  }
}

@keyframes flash-25 {
  0% {
    opacity: 0;
  }
  35% {
    opacity: 0;
  }
  36% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  45% {
    opacity: 1;
  }
  46% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes hoverz-forwards-z30 {
  0% {
    transform: translateZ(30px);
  }
  50% {
    transform: translateZ(0px);
  }
  100% {
    transform: translateZ(30px);
  }
}

@keyframes hoverz-forwards-z10 {
  0% {
    transform: translateZ(10px);
  }
  50% {
    transform: translateZ(20px);
  }
  100% {
    transform: translateZ(10px);
  }
}

@keyframes rotatez-z30 {
  0% {
    transform: translateZ(30px) rotate(0deg)
  }
  25% {
    transform: translateZ(30px) rotate(1deg)
  }
  50% {
    transform: translateZ(30px) rotate(0deg)
  }
  75% {
    transform: translateZ(30px) rotate(-1deg)
  }
  100% {
    transform: translateZ(30px) rotate(0deg)
  }
}

@keyframes hovery-zn40 {
  0% {
    transform: translateZ(-40px) translateY(0px)
  }
  50% {
    transform: translateZ(-40px) translateY(3px)
  }
  100% {
    transform: translateZ(-40px) translateY(0px)
  }
}

@keyframes hovery-z20 {
  0% {
    transform: translateZ(20px) translateY(0px)
  }
  50% {
    transform: translateZ(20px) translateY(5px)
  }
  100% {
    transform: translateZ(20px) translateY(0px)
  }
}

@keyframes hovery-z10 {
  0% {
    transform: translateZ(10px) translateY(0px)
  }
  50% {
    transform: translateZ(10px) translateY(5px)
  }
  100% {
    transform: translateZ(10px) translateY(0px)
  }
}

@keyframes linear-wipe {
  from {
    white-space: nowrap;

    overflow: hidden;
    opacity: 0;
    width: 0;
  }
}

@keyframes stretchx-zn30 {
  0% {
    transform: translateZ(-30px) scale(0.5, 1);
  }
  50% {
    transform: translateZ(-30px) scale(1, 1);
  }
  100% {
    transform: translateZ(-30px) scale(0.5, 1);
  }
}
header.MuiPaper-elevation4.MuiPaper-root {
    box-shadow: none !important;
}

.MuiPaper-root:not(.MuiAppBar-root) {
    background-color: #020C17 !important;
}

li.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root {
    font-size: 1.4rem !important;
}

.MuiAppBar-positionFixed {
    right: 5px !important;
}

span.MuiChip-label {
    font-size: 1.4rem;
}

